import React from 'react';
import './ContactHeading.css';

function ContactHeading() {
  return (
      <div className='contact-heading-wrapper'>
          CONTACT
      </div>
  );
}

export default ContactHeading;
